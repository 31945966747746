.text-editor {
  border-radius: 4px;
  border: 1px solid #E3E3E3;
  background: #FBFBFB;
  &--border-error {
    border:  1px solid #FF3D54;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
    transition: 0.3s;
  }
  &__content {
    min-height: 200px;
    padding: 0 10px;
  }
  &__label {
    color: #303030;
    font-feature-settings: 'salt' on;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.07px; 
    margin-bottom: 8px;
  }
  &__error {
    color: #FF3D54;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
  }
}

.rdw-list-wrapper {
  .rdw-option-wrapper {
    &:nth-child(3), &:nth-child(4) {
      display: none;
    }
  }
}
.rdw-editor-toolbar {
  .rdw-text-align-wrapper {
    display: none;
  }
  .rdw-inline-wrapper {
    .rdw-option-wrapper {
      &:nth-child(3),&:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
        display: none;
      }
    }
  }
  .rdw-block-wrapper, .rdw-fontsize-wrapper, .rdw-fontfamily-wrapper, .rdw-colorpicker-wrapper, .rdw-embedded-wrapper, .rdw-emoji-wrapper, .rdw-image-wrapper, .rdw-remove-wrapper, .rdw-history-wrapper {
    display: none;
  }
  .rdw-link-wrapper {
    display: none;
  }
}