.add-administrator-modal {
  max-width: 463px;
  &__form {
    width: 100%;
    @include mobile-only {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  &__buttons {
    margin-top: 40px;
    height: 48px;
    display: flex;
    gap: 10px;
    @include mobile-only {
      margin-top: auto;
    }
    .btn {
      width: 100%;
    }
  }
}