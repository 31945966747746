.custom-node {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: unset;
  &__body {
    background-color: unset !important;
    text-align: center;
    padding: 0 5px;
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}

.makeCustomHandle {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  border-radius: 0 !important;
  transform: none !important;
  border: none !important;
  opacity: 0 !important;
}

.custom-node:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  height: 80%;
  width: 80%;
  transform: translate(-50%, -50%);
  background: transparent;
  z-index: 1000;
  line-height: 1;
  border-radius: 4px;
  color: #fff;
  font-size: 9px;
  border-radius: 50%;
  // cursor: pointer;
}

.default-color {
  background-color: #FECCA0 !important;
  color: #202020;
}
.circleSize {
  width: 115px !important;
  height: 115px !important;
  &--1 {
    width: 115px !important;
    height: 115px !important;
  }
  
  &--2 {
    width: 130.56px !important;
    height: 130.56px !important;
  }
  
  &--3 {
    width: 146.12px !important;
    height: 146.12px !important;
  }
  
  &--4 {
    width: 161.68px !important;
    height: 161.68px !important;
  }
  
  &--5 {
    width: 177.24px !important;
    height: 177.24px !important;
  }
  
  &--6 {
    width: 192.8px !important;
    height: 192.8px !important;
  }
  
  &--7 {
    width: 208.36px !important;
    height: 208.36px !important;
  }
  
  &--8 {
    width: 223.92px !important;
    height: 223.92px !important;
  }
  
  &--9 {
    width: 239.48px !important;
    height: 239.48px !important;
  }
  
  &--10 {
    width: 255px !important;
    height: 255px !important;
  }
}

.colorSaturation {
  &--1 {
    background-color: #FEE1C8 !important;
  }
  &--2 {
    background-color: #FAD3B0 !important;
  }
  &--3 {
    background-color: #FECCA0 !important;
  }
  &--4 {
    background-color: #F9C08E !important;
  }
  &--5 {
    background-color: #F4B075 !important;
  }
  &--6 {
    background-color: #EEA15F !important;
  }
  &--7 {
    background-color: #EA8C3B !important;
  }
  &--8 {
    background-color: #E57515 !important;
    // color: #fff !important;
  }
  &--9 {
    background-color: #D56D15 !important;
    // color: #fff !important;
  }
  &--10 {
    background-color: #C66513 !important;
    // color: #fff !important;
  }
}

.contourSize {
  border: 1px solid #FF8934;
  &--1 {
   border: 1px solid #FF8934 !important;
  }
  &--2 {
    border: 2px solid #FF8934 !important;
  }
  &--3 {
    border: 3px solid #FF8934 !important;
  }
  &--4 {
    border: 4px solid #FF8934 !important;
  }
  &--5 {
    border: 5px solid #FF8934 !important;
  }
  &--6 {
    border: 6px solid #FF8934 !important;
  }
  &--7 {
    border: 7px solid #FF8934 !important;
  }
  &--8 {
    border: 8px solid #FF8934 !important;
  }
  &--9 {
    border: 9px solid #FF8934 !important;
  }
  &--10 {
    border: 10px solid #FF8934 !important;
  }
}
