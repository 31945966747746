.textarea {
  padding: 9px 16px;
  border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #E3E3E3;
  background: #FBFBFB; 
  &:focus-visible {
    border: 1px solid #7D529C;
    outline: none;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  &__label {
    color: #303030;
    font-feature-settings: 'salt' on;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.07px; 
    margin-bottom: 8px;
    display: flex;
  }
  &__optional {
    margin-left: 5px;
    font-weight: 500;
  }
  &__error {
    font-size: 14px;
    color: #FF3D54;
  }
  &--border-error {
    border: 1px solid #FF3D54;
  }
}