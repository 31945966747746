.checkbox {
  margin-top: 20px;
  &__input {
    margin-left: 0;
  }
  &__option {
    display: flex;
    cursor: pointer;
  }
  .input__error {
    margin-top: 0;
  }
  &__title-wrapper {
    margin-left: 15px;
  }
}