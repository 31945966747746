.change-password-modal {
  max-width: 460px;
  &__form {
    width: 100%;
  }
  &__buttons {
    height: 48px;
    display: flex;
    gap: 10px;
    .btn {
      width: 100%;
    }
  }
}