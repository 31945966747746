.get-lost {
  height: 100%;
  width: 100%;
  display: flex;
  -ms-flex-align: center;
  justify-content: center;
  padding-top: 138px;
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__header {
    color: #202020;
    text-align: center;
    font-feature-settings: 'salt' on;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px; 
    margin-top: 58px;
    text-align: center;
  }
  &__paragraph {
    color: #202020;
    font-feature-settings: 'salt' on;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.16px; 
    text-align: center;
    margin-top: 10px;
  }
}
.finish-link {
  margin-top: 40px;
}