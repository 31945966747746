.modal {
  position: fixed;
  left: 50%;
  transform: translate(calc(-50% + 40px), 100px);
  width: calc(100% - 40px);
  height: fit-content;
  max-width: 608px;
  border-radius: 8px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(17, 19, 56, 0.08);
  padding: 20px 30px;

  @include mobile-only {
    width: 100%;
    height: calc(100% - 100px);
    transform: translate(calc(-50%), 100px);
    max-width: 100% !important;
    justify-content: flex-start;
    padding-bottom: 60px;
  }

  &__backdrop {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background: rgba(34, 36, 80, 0.2);
    backdrop-filter: blur(1px);
    pointer-events: none;

    &--open {
      opacity: 1;
      pointer-events: all;
    }
  }
  
}

.modal-description {
  color: #303030;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &--full {
    width: 100%;
  }
}

.modal-heading {
  color: #202020;
  font-feature-settings: 'salt' on;
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.2px;
  width: 100%;
  margin-bottom: 30px;
}