.badge {
  padding: 5px 18px;
  border-radius: 4px;
  display: inherit;
  &--bold {
    background-color: #DCABFF;
    p {
      color: #24133C;
      font-weight: 600;
    }
  }
  &--medium {
    background-color: #FFD0E5;
    p {
      color: #492F6C;
      font-weight: 600;

    }
  }
  &--light {
    background-color: #E9ECFF;
    p {
      color: #5F6BB7;
      font-weight: 600;

    }
  }
}