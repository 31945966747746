.last-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  &__canvas {
    max-width: 560px;
    height: auto;
    object-fit: cover;
  }
  &__content {
    max-width: 560px;
    width: 100%;
    padding-top: 20px;
    .slider__wrapper {
      margin-bottom: 30px;
    }
    ul {
      list-style: disc inside;
      padding-left: 0;
    }
    ul li {
      text-decoration: dashed;
      font-size: 14px;
    }
    ol {
      list-style-type: decimal;
      margin-left: 15px;
    }
  }
  &__text {
    margin: 20px 0;
    p {
      margin: 15px 0;
    }
    strong {
      font-weight: 700;
    }
  }
  &__buttons {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
  }
  .btn {
    max-width: 280px;
    width: 100%;
    height: 48px;
  }
  .slider__descript {
    margin-bottom: 20px;
  }
  .textarea__label, .slider__label {
    // margin-bottom: 15px;
    color: #000;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 23.2px */
  }
  .rc-slider {
    width: calc(100% - 10px);
    span {
      line-height: 15px !important;
    }
  }
}