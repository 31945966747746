.sidebar-target {
  height: 100%;  
  background: #FFF;
  padding: 20px 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 0;
  transition: 0.3s;
  opacity: 0;
  &--active {
    opacity: 1;
    transition: 0.3s;
    width: 418px;
    padding: 20px 30px;
    box-shadow: -6px 2px 30px 0px rgba(0, 0, 0, 0.03);
    border-top: 1px solid #E3E3E3;
    border-right: 1px solid #E3E3E3;
    border-left: 1px solid #E3E3E3;
  }
  &__heading {
    color: #202020;
    font-feature-settings: 'salt' on;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.2px; 
    margin-bottom: 20px;
  }
  .btn {
    height: 48px;
  }
  .rc-slider {
    // margin-bottom: 50px;
  }
  .input--border-error {
    border: 1px solid #E3E3E3 !important;
  }
  .slider__error {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .btn-delete {
    display: flex;
    padding: 8px 50px;
    align-items: center;
    color: #4F4294;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
    svg  {
      margin-left: 6px;
      path {
        stroke: #4F4294;
      }
    }
  }
}

