@import 'functions';

$breakpoints: sm, md, lg, xl, xxl;
$screens: (
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
);

/* Small devices */
@mixin sm {
  @media (min-width: map-get($screens, sm)) {
    @content;
  }
}

/* Medium devices */
@mixin md {
  @media (min-width: map-get($screens, md)) {
    @content;
  }
}
/* Large devices */
@mixin lg {
  @media (min-width: $screen-lg) {
    @content;
  }
}
/* Extra large devices */
@mixin xl {
  @media (min-width: map-get($screens, xl)) {
    @content;
  }
}
/* Extra large devices */
@mixin xxl {
  @media (min-width: map-get($screens, xxl)) {
    @content;
  }
}

@mixin mobile-only {
  @media (max-width: $screen-lg) {
    @content;
  }
}
/* Custom breakpoint */
@mixin rwd($screen) {
  $screen: strip-unit($screen);
  @media (min-width: $screen) {
    @content;
  }
}
