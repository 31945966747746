@import './components/AddAdministratorModal.scss';
@import './components/SuspendUserModal.scss';

.administrator-page__add-user {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  background-color: #4F4294;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}