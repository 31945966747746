.input {
  padding: 9px 16px;
  border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #E3E3E3;
  background: #FBFBFB; 
  min-height: 48px;
  font-size: 14px;
  transition: 0.3s;
  &:focus-visible {
    border: 1px solid #7D529C;
    outline: none;
  }
  ::placeholder { 
    font-size: 14px;
  }
  &--border-error {
    border:  1px solid #FF3D54;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
    transition: 0.3s;
  }
  &__label {
    color: #303030;
    font-feature-settings: 'salt' on;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.07px; 
    margin-bottom: 8px;
  }
  &__change-password {
    position: absolute;
    right: 10px;
    top: 37px;
    cursor: pointer;
    svg path {
      fill: #7D7D7D;
    }
  }
  &__error {
    color: #FF3D54;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
  }
}


.input-number {
  &__marks {
    position: absolute;
    display: flex;
    gap: 12px;
    right: 10px;
    top: 40px;
  }
  &__mark {
    cursor: pointer;
    &--disabled {
      .svg {
        path {
          stroke: #D1D1D1;
        }
      }
    }
  }
  
}

.pointerEventsNone {
  input {
    pointer-events: none;
  }
}