.select {
  margin-top: 8px;
  min-height: 48px;
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    z-index: 9;
  }
  &__control {
    min-height: 48px !important;
    background-color: #FBFBFB !important;
    border: 1px solid #E3E3E3;
    &--is-focused {
      box-shadow: unset !important;
      border-color: unset !important;
    }
  }
  
  &__label {
    color: #303030;
    font-feature-settings: 'salt' on;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.07px;
  }
  &__indicator-separator {
    display: none !important;
  }
  &__placeholder {
    font-size: 14px !important; 
  }
  &__option {
    color: #303030;
    &__is-active {
      display: none;
    }
    &--is-focused {
      background-color: #E3E3E3 !important;
    }
  }
  div &__option {
    padding: 0 17px;
    display: flex;
    align-items: center;
    height: 48px;
    cursor: pointer;
    &:first-child {
      margin-top: -5px;
    }    
    &:last-child {
    margin-bottom: -4px;
    }
    &:hover {
      background-color:#E3E3E3 !important;
      color: #303030;
    }
    &--is-selected {
      background-color: #fff !important;
      color: #303030;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 20px;
        height: 20px;
        background: url(../../../Assets/file/check-circle.svg);
      }
      &:hover {
        background-color: #fff !important;
      }
    }
    &:active {
      background-color: #E3E3E3 !important;
    }
  }

}

// .select__option {
//   line-height: 20px !important;
//   // margin: 10px 0 !important;
//   max-height: 100px !important;
//   height: auto !important;
//   display: flex !important;
//   font-size: 14px !important;
//   a, div {
//     padding: 10px !important;
//     padding-left: 0 !important;
//     min-height: auto !important;
//     max-height: 60px !important;
//     height: 100%;
//   }
// }