@import './components/CustomNode.scss';
@import './components/BoardInfo.scss';

.react-flow__handle-left {
  // margin-left: 4px;
  left: -3px !important;
}
.react-flow__handle-right {
  // margin-right: 4px;
  right: -3px !important;
}

.target {
  height: calc(100vh - 50px);
  display: flex;
  overflow: hidden;
  .react-flow {
    &__edge {
      position: relative;
    }
    &__handle {
      border: unset !important;
      background-color: transparent !important;
      width: calc(100% + 6px) !important;
      height: calc(100% + 6px) !important;
      z-index: 99;
      border-radius: 50% !important;
    }
    &__controls {
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: unset;
      box-shadow: unset;
    }
    &__controls-button {
      width: 40px;
      height: 40px;
      border-radius: 30px;
      border: 1px solid #E3E3E3;
    }
    &__controls  {
    right: 20px;
    bottom: 40px !important;
    left: unset !important;
    .react-flow__controls-fitview, 
    .react-flow__controls-interactive {
      display: none;
    }}
    &__node {
      width: 100px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      
    }
    &__connectionline {
      z-index: 0;
    }
  }
}

.react-flow__edge-path {
  &:after {
    content: '';
    height: 10px;
    width: 2px;
  }
}

.node-selected-element {
  .custom-node {
    &__body {
      line-height: 20px;
    }
    &:after {
      border-radius: 50%;
      content: '';
      position: absolute;
      top: -7px;
      left: -7px;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      border: 2px solid #FE16F5 ;
    }
  }
  box-shadow: unset !important;
}

.board-target {
  height: 100%;
  width: 100%;
  transition: 0.3s;
  &--active {
    transition: 0.3s;
    width: calc(100% - 418px);
  }
}

.target {
  .handle--hidden {
    height: 0 !important;
    width: 0 !important;
    pointer-events: none !important;
    min-width: 1px;
    min-height: 1px;
    z-index: -1;
  }
}

#edge-controls {
  position: absolute;
  width: 405px;
  height: 56px;
  z-index: 99;
  border-radius: 4px;
  border: 1px solid #E3E3E3;
  background: #FFF;
  box-shadow: 0px 3px 30px 0px rgba(32, 32, 32, 0.04); 
  display: flex;
    .select__control {
      background-color: #fff !important;    
    }
}

.edge-controls-hidden {
  overflow: hidden;
  max-height: 0;
  border: 0 !important;
  box-shadow: unset;
}

.edge-controls {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  &__remove {
    width: 60px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__select {
    width: 50%;
    height: 100%;
    border-right: 1px solid rgba(224, 224, 224, 1);
    position: relative;
    background-color: #fff;
    position: relative;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &--onclick {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 54px;
    }
    .select-edge {
      height: 100%;
      &__error {
        border: 1px solid #FF3D54;
      }
    }
    &--disabled {
      .select-edge {
        pointer-events: none;
      }
    }
    .select__control {
      height: 100%;
      border: unset;
      &--is-focused {
        border: unset;
      }
      .select__placeholder {
        color: #303030;
        font-family: Source Sans Pro;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal; 
      }
    }
    .css-t3ipsp-control {
      box-shadow: unset;
    }
    .select__indicator-separator {
      display: none;
    }
    .select__menu {
      margin-top: 0;
    }
  }
  &__select-slider {
    padding: 10px 20px 10px 10px;
    position:absolute;
    top: 55px;
    width: 100%;
    border: 1px solid hsl(0, 0%, 100%);
    background-color: hsl(0, 0%, 100%);
    z-index: 9999;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.04);
    height: 100px;
    border-radius: 0px 0px 4px 4px;
    border-right: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    border-left: 1px solid #E3E3E3; 
  }
  &__select-slider-label {
    display: flex;
    font-size: 12px;
    color: #666;
    margin-top: 30px;
    justify-content: space-between;
    p {
      max-width: 50px;
      &:last-child {
        margin-right: -10px;
        text-align: right;
      }
    }
  }
}

#focused-node {
  position: relative;
  z-index: 9999;
}

#edge-controls {
  .edge-controls__select:nth-child(2) {
    .select-edge__error {
      margin-left: -2px;
    }
  }
}