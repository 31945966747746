@import "./components/GetLost.scss";
@import "./components/LastPage.scss";
@import "./components/FirstPage.scss";

.home-page {
  &__loader {
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}