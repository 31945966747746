.delete-test-modal {
  max-width: 460px;
  &__descript {
    width: 100%;
    text-align: left;
    color: #303030;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      font-weight: 600;
    }
  }
  &__buttons {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    .btn {
      width: 50%;
      height: 50px;
    }
  }
}