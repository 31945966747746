.drop-file-input {
  background: #fff;
  padding: 15px 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  transition: 0.3s;
  border-radius: 4px;
  max-height: 48px;
  height: 48px;
  display: flex;
  z-index: 10;
  align-items: center;
  position: relative;
  justify-content: space-between;

  border-radius: 4px;
  border: 1px solid #E3E3E3;
  background: #FBFBFB;
  
  &--file-inside {
    padding: 15px 10px;
  }
  &__content {
    color: #202020;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #949494;
    font-feature-settings: 'salt' on;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.07px;
    button {
      outline: none;
    }
    &--file {
      color: #222450;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #EFEFEF;
      padding: 7.5px 0;
      border-radius: 6px;
    }
  }
  &__input {
    display: none;
  }
  &__button {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #202020;
    border: 2px solid #202020;
    border-radius: 4px;
    padding: 4px 35px;
    height: 32px;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s;
    margin-left: 20px;
    
  }
  &__remove {
    display: flex;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
  }
  .summary__file {
    // height: 32px;
    margin-bottom: unset;
    padding: 0 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;  
  }
  &__wrapper {
    position: relative;
    margin-bottom: 24px;
    .input__error {
      bottom: -15px;
    }
    &--error {
      .drop-file-input {
        border: 1px dashed red;
      }
    }
  }
  &__outside {
    position: fixed;
    top: 0;
    left: 0;
    transform: scale(2);
    width: 100vw;
    height: 100vh;
    z-index: 9;
    transition: 0.6s;
    opacity: 0;
    background-color: black;
    pointer-events: none;
    &--show {
      opacity: 0.5;
    }
  }
}
