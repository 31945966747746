.top-bar {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 48px;
  border: 0.5px solid #E3E3E3;
  background: #FFF;
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.06);
  // padding-right: 30px;
  &--first-page {
    position: fixed;
  }
  &--right {
    display: flex;
    min-width: 418px;
    justify-content: space-between;
    padding: 0 30px;
    .btn {
      max-height: 32px;
      display: flex;
    align-items: center;

    }
    .add-target {
      margin-left: auto;
    }
  }
  &__end-map-modal {
    max-width: 460px;
    &__buttons {
    margin-top: 40px;
      width: 100%;
      display: flex;
      gap: 20px;
      .btn {
        width: 100%;
        height: 48px;
      }
    }
    
  }
}

.top-bar-application-name {
  color: #FFF;
  font-feature-settings: 'salt' on;
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 
}

