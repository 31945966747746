.test-form-slider-mark {
  &__form-inputs {
    margin-top: 45px;
  }
  &__form-input-label {
    color: #303030;
    font-feature-settings: 'salt' on;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.07px;
    margin-top: 20px;
  }
  &__form-input input{
    width: 100%;
    margin-top: 8px;
    min-height: 48px;
    border-radius: 4px;
    border: 1px solid #E3E3E3;
    background: #FBFBFB;
    padding: 8px 16px;
  }
  &__number {
    margin-bottom: 10px;
  }
  .btn {
    background-color: #fff;
    cursor: pointer;
  }
  input {
    height: 32px;
    background-color: #E3E3E3;
    border-radius: 4px;
    border: 1px solid #E3E3E3;
    background: #FBFBFB;
    &:focus-visible {
      border: 1px solid #7D529C;
      outline: none;
    }
  }
  &__input {
    display: flex;
    align-items: center;
    .svg {
      margin-left: 6px;
    }
  }
  &__input-label {
    display: flex;
    justify-content: center;
    min-width: 70px;
    max-width: 70px;
    text-align: left;
    color: #202020;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.07px;
    display: flex;
    min-height: 32px;
    padding-top: 7px;
    padding-left: 2px;
    .svg {
      flex-shrink: 0;
      margin-left: 5px;
    }
  }
} 
.add-test-form__frame {
  .rc-slider {
    .rc-slider-mark {
      .btn {
        min-width: 100px;
      }
      .btn-small {
        min-width: unset;
      }
      input {
        max-width: 100px;
        
      }
      .rc-slider-mark-text {
        &:first-child {
          .test-form-slider-mark__input-label {
            display: flex;
            justify-content: flex-start;
          }
          .test-form-slider-mark--big {
          margin-left: 90px !important;
          text-align: left;
            @include mobile-only {
              margin-left: 0 !important;;
            }
          }
          .test-form-slider-mark--small { 
            margin-left: 40px;
            text-align: left;
            @include mobile-only {
              margin-left: 0 !important;;
            }
          }
        }

        &:last-child {

          .test-form-slider-mark--big {
            margin-right: 90px !important;
            text-align: right;
            @include mobile-only {
              margin-right: 0 !important;;
            }
            .test-form-slider-mark__input-label {
              display: flex;
              justify-content: flex-end;
            }
            }
          .test-form-slider-mark--small { 
            margin-right: 40px;
            text-align: right;
            @include mobile-only {
              margin-right: 0 !important;;
            }
          }
        }
      }
    }
  }
}
