.mobile-menu {
  display: flex;
  flex-wrap: wrap;
  &__icon {
    .svg path{
      stroke: #202020;
    }
  }
  &__wrapper {
    position: relative;
    z-index: 999;
  }
  &__content {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: -1500px;
    left: 0;
    background: #FBFBFB;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    padding-top: 140px;
    &--active {
      top: 0;
      transition: 0.3s;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    text-decoration: unset;
  }
  &__item {
    text-decoration: unset;
    text-align: center;
    color: #4F4294;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.09px;
    margin-bottom: 40px;
    &--active {
      font-weight: 700;
    }
  }
  &__logout {
    color: #FF3D54;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.09px;  
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
  }
}