.administrators-list {
  margin-top: 24px;
  @include mobile-only {
    padding-bottom: 30px;
  }
  &__pagination {
    margin-bottom: 20px;
  }
  &__table {
    width: 100%;
  }
  &__badge {
    display: flex
  }
  &__table-list {
    border: 1px solid #E3E3E3; 
    border-radius: 4px 4px 0 0;
    display: flex;
  }
  &__thead {
    width: 100%;
    p {
      text-align: left;
      padding: 15px 0;
      background: #F1F1F1; 
      color: #303030;
      font-feature-settings: 'salt' on;
      font-family: Source Sans Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.07px;
      width: 25%;
    }
    p:first-child {
      border-radius: 4px 0 0 0;
      padding-left: 29px;
    }
    p:last-child {
      border-radius: 0 4px 0 0;
      width: 60px;
    }
  }
  &__tbody-list {
    border: 1px solid #E3E3E3; 
    display: flex;
  }
  &__tbody-item {
      padding: 15px 0;
      color: #303030;
      font-feature-settings: 'salt' on;
      font-family: Source Sans Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.07px;
      display: flex;
      width: 25%;
      align-items: center;
    &:first-child {
      padding-left: 29px;
    }
    &:last-child {
      cursor: pointer;
      padding-right: 20px;
      width: 60px;
      display: flex;
      justify-content: flex-end;
    }
    
  }
}




.administrator-list-settings-menu {
  position: relative;
}
.administrator-list-settings {
  position: relative;
  &--active {
    pointer-events: none;
  }
  &__content {
    position: absolute;
    top: 18px;
    right: 0;
    width: 163px;
    border-radius: 4px;
    border: 0px solid transparent;
    background: #FFF;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
    z-index: 999999;
    &--show {
      transition: 0.3s;
      max-height: 200px;
      border: 1px solid #E3E3E3;
    }
  }
  &__label {
    color: #303030;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.07px;
    padding: 11px 23px;
    z-index: 999;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    &:hover {
      transition: 0.3s;
      background-color: #E3E3E3;
    }
  }
}

.administrator-mobile-list {
  &__item {
    display: flex;
    flex-direction: column;
    border: 1px solid #E3E3E3;
    padding: 14px 16px;
  }
  &__item-top {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    cursor: pointer;
    position: relative;
  }
  &__item-onClick {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 50px);
    height: 100%;
  }
  &__item-name {
    display: flex;
    align-items: center;
    max-width: 250px;
    width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    line-height: 22px;
    max-height: 22px;
    word-break: break-all;
    line-break: loose;
    overflow: hidden;
    padding-right: 10px;
  }
  &__item-bottom {
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
    &--show {
      margin-top: 25px;
      max-height: 150px;
      transition: 0.3s;
    }
  }
  &__item-dots {
    position: relative
  }
}