.btn {
  color: #FFF;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
  border-radius: 4px;
  background: #4F4294;
  padding: 6px 50px;
  border: unset;
  cursor: pointer;
  position: relative;
  &__loader {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
  &--stroke {
    background-color: unset;
    color: #4F4294;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
    border-radius: 4px;
    border: 2px solid #4F4294;
  }
  &--black {
    border: 1px solid #202020;
    color: #202020;
    background-color: transparent;
    padding: 7px 20px;
    display: flex;
    align-items: center;
    p {
      font-weight: 600 !important;
    }
  }
  &--full-black {
    color: #fff;
    background-color: #202020;
    padding: 7px 20px;
    display: flex;
  }
}
.icon-right {
  margin-left: 8px;
}