/* Import dla wersji "Black" */

@font-face {
  font-family: "Source Sans Pro";
  src: url("./../fonts/SourceSansPro-Black.otf") format("opentype");
  font-weight: 900;  
  font-style: normal;
}

/* Import dla wersji "Black Italic" */
@font-face {
  font-family: "Source Sans Pro";
  src: url("./../fonts/SourceSansPro-BlackIt.otf") format("opentype");
  font-weight: 900;  
  font-style: italic;
}

/* Import dla wersji "Bold" */
@font-face {
  font-family: "Source Sans Pro";
  src: url("./../fonts/SourceSansPro-Bold.otf") format("opentype");
  font-weight: 700;  
  font-style: normal;
}

/* Import dla wersji "Bold Italic" */
@font-face {
  font-family: "Source Sans Pro";
  src: url("./../fonts/SourceSansPro-BoldIt.otf") format("opentype");
  font-weight: 700;  
  font-style: italic;
}

/* Import dla wersji "Extra Light" */
@font-face {
  font-family: "Source Sans Pro";
  src: url("./../fonts/SourceSansPro-ExtraLight.otf") format("opentype");
  font-weight: 200;  
  font-style: normal;
}

/* Import dla wersji "Extra Light Italic" */
@font-face {
  font-family: "Source Sans Pro";
  src: url("./../fonts/SourceSansPro-ExtraLightIt.otf") format("opentype");
  font-weight: 200;  
  font-style: italic;
}

/* Import dla wersji "Italic" */
@font-face {
  font-family: "Source Sans Pro";
  src: url("./../fonts/SourceSansPro-It.otf") format("opentype");
  font-weight: normal;  
  font-style: italic;
}

/* Import dla wersji "Light" */
@font-face {
  font-family: "Source Sans Pro";
  src: url("./../fonts/SourceSansPro-Light.otf") format("opentype");
  font-weight: 300;  
  font-style: normal;
}

/* Import dla wersji "Light Italic" */
@font-face {
  font-family: "Source Sans Pro";
  src: url("./../fonts/SourceSansPro-LightIt.otf") format("opentype");
  font-weight: 300;  
  font-style: italic;
}

/* Import dla wersji "Regular" */
@font-face {
  font-family: "Source Sans Pro";
  src: url("./../fonts/SourceSansPro-Regular.otf") format("opentype");
  font-weight: normal;  
  font-style: normal;
}

/* Import dla wersji "Semi Bold" */
@font-face {
  font-family: "Source Sans Pro";
  src: url("./../fonts/SourceSansPro-Semibold.otf") format("opentype");
  font-weight: 600;  
  font-style: normal;
}

/* Import dla wersji "Semi Bold Italic" */
@font-face {
  font-family: "Source Sans Pro";
  src: url("./../fonts/SourceSansPro-SemiboldIt.otf") format("opentype");
  font-weight: 600;  
  font-style: italic;
}
