.dashboard-sidebar-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 60px;
  height: 100vh;
  background-color: rgba(32, 32, 32, 1);
  display: flex;
  flex-direction: column;
  z-index: 9999999;
  transition: 0.3s;
  overflow: hidden;
  @include mobile-only {
    display: none;
  }
  &.active {
    width: 260px;
  }
  &__logo {
    height: 60px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 14px;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
    &.active {
      cursor: auto;
      margin-left: 22px;
      .logo {
        width: 74px;
      }
    }
  }
  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    .svg path{
      stroke: #EDEAFF;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
    gap: 12px;
    margin-top: 65px;
  }
  &__item {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.3s;
    &--active {
      transition: 0.3s;
      background-color: rgba(58, 58, 58, 1);
      width: 100%;
    }
    &:hover {
      transition: 0.3s;
      background-color: rgba(58, 58, 58, 0.5);
      .svg {
        scale: 1.1;
        transition: 0.3s;
      }
    }
    .svg {
      transition: 0.3s;
      path {
        stroke: #EDEAFF;
      }
    }
  }
  &__item-image {
    flex-shrink: 0;
    width: 40px;
    display: flex;
    justify-content: center;
  }
  &__item-label {
    color: #FFFFFF;
    margin-left: 18px;
  }
}