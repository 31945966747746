.board-info {
  padding: 30px 24px 30px 16px;
  margin-top: 20px;
  margin-left: 150px;
  border-radius: 4px;
  border: 1px solid #E3E3E3;
  background: #FFF;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  min-width: 564px;
  max-width: 564px;
  position: relative;
  z-index: 9;

  &--hidden {
    max-width: 100%;
    margin-right: 20px;
    padding: 30px 24px 0 16px;
    // min-width: 0;
    overflow: hidden;
    background-color: transparent;
    border: 1px solid transparent;
    box-shadow: unset;
  }
  &__wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    &--hidden {
      max-width: 100%;
    }
  }
  &__icon-hidden {
    position: absolute;
    top: 0;
    right: 20px;
  }
  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 40px;
    margin-right: 16px;
    margin-bottom: auto;
    &--pointer {
      cursor: pointer;

    }
  }
  &__text {
    color: #5B5B5B;
    font-feature-settings: 'salt' on;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    &--error {
      color: #FF3D54;
      font-family: Source Sans Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    p {
      &:nth-child(n + 2) {
        margin: 10px 0;
      }
    }
  }
  &__close-wrapper {
    position: absolute;
    top: 6px;
    right: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #949494;
    font-size: 10px;
    color: #949494;
    font-feature-settings: 'ss02' on;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}


