.dashboard-top-bar {
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 15px 100px 15px 87px; 
  background: #FFF;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.04);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  @include mobile-only {
    padding: 15px 20px;
    z-index: 9999999;
  }
  &__logo {
    margin-top: -8px;
    .svg {
      width: 76px;
      height: 36px;
    }
  }
  &__user {
    cursor: pointer;
    display: flex;
    align-items: center;
    .svg {
      margin-left: 4px;
    }
  }
  &--mobile-active {
    background: #FBFBFB;
    box-shadow: unset;
    border: unset;
  }
}

.dashboard-top-bar-menu {
&__wrapper {
  position: relative;
}
  &__content {
    position: absolute;
    top: 30px;
    right: 0;
    border-radius: 4px;
    border: 1px solid transparent;
    background: #FFF;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    width: 163px;
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
    &--show {
      transition: 0.3s;
      border: 1px solid #E3E3E3;
      max-height: 300px;
    }
  }
  &__item {
    color: #303030;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.07px;
    display: flex;
    flex-direction: column;
    padding: 11px 23px 11px 23px;
    cursor: pointer;
    transition: 0.3s;
    background-color: #fff;
    z-index: 999;
    &:hover {
      transition: 0.3s;
      background-color: #E3E3E3;
    }
    &--logout {
      color: rgba(255, 31, 31, 1);
    }
  }
}