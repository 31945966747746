@import "./components/CustomSlider.scss";

.add-test {
  &--save {
    padding-top: 20px;
    max-width: 280px;
    @include mobile-only {
      max-width: 100%;
      .btn {
        width: 100%;
      }
    }
  }
  &--save-second-step {
    padding-top: 0;
    margin-bottom: 30px;
  }
  &--save-third-step {
    margin: 10px 0;
  }
  &__wrapper {
    margin-top: 34px;
    display: flex;
    flex-direction: column;
    border-radius: 4px 4px 0 0;
    border: 1px solid #E3E3E3;
    background: #FFF;
    margin-bottom: 50px;
    @include mobile-only {
      margin-top: 0;
      padding-top: 19px;
      border: unset;
      &--first-step { 
        height: 100%; 
        // height: calc(100% - 26px);
      }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0 82px;
    background-color: #F1F1F1;
    @include mobile-only {
      padding: 0;
      flex-direction: column;
      background-color: unset;
    }
  }
  &__header-item {
    color: #202020;
    font-feature-settings: 'ss03' on, 'ss02' on, 'salt' on;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 15px 0;
    @include mobile-only {
      padding-top: 0;
      display: none;
    }
    &--active {
      color: #EE7EB0;
      @include mobile-only {
        color: #202020;
        display: block;
      }
    }
  }
  &__header-progress {
    display: flex;
    gap: 20px;
    &__item {
      width: calc(100% / 4);
      height: 9px;
      border-radius: 2px;
      background-color: #D7D7D7;
      transition: 0.3s;
      &--active {
        transition: 0.3s;
        background-color: #202020;
      }
    }
  }
  &__content {
    padding: 48px 100px 40px 100px;
    display: flex;
    flex-direction: column;

    @include mobile-only {
      padding: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      &--second {
      padding-top: 10px;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    @include mobile-only {
      gap: 20px;
      padding-bottom: 20px;
    }
    .btn {
      max-width: 280px;
      width: 100%;
      min-height: 40px;
      max-height: 40px;
      height: 100%;
      @include mobile-only {
        max-width: unset;
        width: 50%;
        max-height: unset;
      }
    }
    &--next {
      display: flex;
      flex-direction: column;
      max-width: 280px;
      width: 100%;
      @include mobile-only {
        max-width: unset;
        width: 50%;
      }
      .btn {
        width: 100%;
      }
    }
    &--error {
      color: #FF3D54;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 7px;
    }
  }
  &__buttons-first-step {
    margin-top: 220px;
    justify-content: flex-end;
    @include mobile-only {
      margin-top: auto;
    }
  }
  &__status-button {
    z-index: 9;
  }
  &__language-button {
    z-index: 8;
  }
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.add-test-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  .btn {
    min-height: 40px;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }
  }
  @include mobile-only {
    margin-top: 30px;
  }
  &__heading {
    color: #000;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.08px;
    margin-bottom: 29px;
  }
  &__frame {
    border-radius: 5px;
    border: 1px solid #E3E3E3;
    padding: 14px 20px 30px 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    transition: 0.3s;
    &:nth-child(1) {
      margin-top: 0;
    }
    &--border {
      &:hover, &:active, &:focus {
        border: 1px solid #EE7EB0;
      }
    }
    &:hover, &:active, &:focus {
      transition: 0.3s;
      .add-test-form__frame--remove {
        opacity: 1;
        // transition: 0.3s;
      }
    }
    &--third {
      // padding-bottom: 130px;
      @include mobile-only {
        padding-bottom: 27px;
      }
    }
    &--error {
      border: 1px solid red;
    }
    &--remove {
      position: absolute;
      top: -12px;
      right: -14px;
      border-radius: 50%;
      border: 1px solid #EE7EB0;
      width: 32px;
      height: 32px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      cursor: pointer;
      // transition: 0.3s;
      .svg {
        transition: 0.3s;
      }
      &:hover {
        .svg {
          scale: 1.1;
          // transition: 0.3s;
        }
      }
      .svg path {
        stroke: #EE7EB0;
      }
    }
  }
  &__half {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    @include mobile-only {
      margin-top: 0;
      flex-direction: column;
      gap: 0;
    }
    .input__wrapper, .select__wrapper {
      width: 50%;
      @include mobile-only {
        width: 100%;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__item {
    position: relative;
    transition: 0.3s;
    &:hover, &:active, &:focus {
      .text-editor, .drop-file-input {
        border: 1px solid #EE7EB0;
        transition: 0.3s;
      }
      .add-test-form__item--remove {
        opacity: 1;
        transition: 0.3s;
        z-index: 99;
      }
    }
    &--remove {
      position: absolute;
      top: 12px;
      right: -14px;
      border-radius: 50%;
      border: 1px solid #EE7EB0;
      width: 32px;
      height: 32px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      cursor: pointer;
      // transition: 0.3s;
      .svg {
        transition: 0.3s;
      }
      &:hover {
        .svg {
          scale: 1.1;
          transition: 0.3s;
        }
      }
      .svg path {
        stroke: #EE7EB0;
      }
    }
  }
  // .rc-slider {
  //   height: unset;
  //   .rc-slider-mark {
  //     position: relative;
  //     .rc-slider-mark-text {
  //       position: relative;
  //     }
  //   }
  // }
}

.add-test-form-second {
  margin-bottom: 30px;
  &__buttons-content {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
  }
  &__buttons-error {
    margin-top: 20px;
    color: #FF3D54;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.add-test-form-third, .add-test-form-fourth {
  margin-bottom: 20px;
  .rc-slider-handle {
    display: none;
  }
  .rc-slider-dot-active {
    background: #fff !important;
    border: 2px solid #e9e9e9 !important; 
  }
  .rc-slider-track {
    background-color: #e9e9e9 !important;
  }
}



// .add-test-screen-editor {
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 20px;
//   &__heading {
//     color: #303030;
//     font-feature-settings: 'salt' on;
//     font-family: Source Sans Pro;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 600;
//     line-height: normal;
//     letter-spacing: -0.07px;
//     margin-bottom: 8px;
//   }
//   &__content {
//     border-radius: 4px;
//     border: 1px solid #E3E3E3;
//     background: #FBFBFB;
//   }
// }

.add-test__text {
  .editorClassName{
    min-height: 150px;
  }
}

.add-test-form-fourth {
  min-height: 600px;
  &__buttons {
    display: flex;
    gap: 13px;
    margin-bottom: 20px;
  }
  .rc-slider {
    margin-bottom: 70px;
  }
}