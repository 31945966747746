.password-validation {
  &__list {
    margin-bottom: 30px;
  }
  &__vertical {
    display: flex;
    width: 100%;
    gap: 12px;
    margin-top: -10px;
  }
  &__vertical-item {
    width: calc(100% / 3);
    height: 6px;
    border-radius: 20px;
    background-color: #FF9A02;
  }
  &__power-text {
    margin-bottom: 16px;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; 
    margin-top: 2px;
  }
}

.validation-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  .svg {
    margin-right: 8px;
  }
  p {
    color: var(--czarny-300, #333);
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
  }
  &__wrapper {
    overflow: hidden;
    transition: 0.3s;
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    &--show {
      padding-top: 23px;
      max-height: 159px;
      margin-bottom: 20px;
    }
  }
}