.slider {
  padding: 9px 16px;
  border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #E3E3E3;
  background: #FBFBFB; 
  &--empty {
    .rc-slider-handle {
      background-color: #e9e9e9 !important;
      border: 2px solid #e9e9e9 !important;
    }
  }
  &--error {
    .rc-slider-handle {
      background-color: #FF3D54 !important;
      border: 2px solid #FF3D54 !important;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    // margin-bottom: 100px;
  }
  &__label {
    color: #303030;
    font-feature-settings: 'salt' on;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.07px; 
    margin-bottom: 8px;
    display: flex;
  }
  &__optional {
    margin-left: 5px;
    font-weight: 500;
  }
  &__descript {
    color: #5B5B5B;
    font-feature-settings: 'salt' on;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    margin-bottom: 41px;
  }
  &__error {
    margin-top: 40px;
    font-size: 12px;
    color: #FF3D54;
  }
}
.slider__marks {
  display: flex;
  flex-direction: column;
  &--left{
    text-align: left;
    margin-left: 30px;
    max-width: 40px;
  }
  &--left-long{
    text-align: left;
    margin-left: 40px;
  }
  &--right{
    text-align: right;
    margin-right: 35px;
  }
}

// .rc-slider-mark-text {
//   white-space: pre-line;
//   &:after {
//     content: '\A';
//   }
// }

.rc-slider-dot-active, .rc-slider-handle {
  opacity: 1 !important;
  border-color: #7919FF !important;
  background-color: #7919FF !important;
}
.rc-slider-track {
  background-color: #7919FF !important;
}
.rc-slider {
  width: calc(100% - 10px);
  margin-left: 5px;
  .text {
    word-break: break-word;
  }
}

.rc-slider-handle {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

/* Jeśli chcesz wyłączyć również zaznaczanie na wskaźnikach suwaka, użyj poniższego kodu */
.rc-slider-mark-text {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.last-page__content .rc-slider-mark-text, .sidebar-target .rc-slider-mark-text {
  max-width: 65px;
}