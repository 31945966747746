.answers-test {
  &__heading {
    @include mobile-only {
      flex-wrap: wrap;
      .btn {
        margin-top: 20px;
        width: 100%;
        max-width: 100% !important;
      }
    }
  } 
  &__heading-button {
    display: flex;
    max-width: 220px !important;
    align-items: center;
    justify-content: center;
    padding: 6px 30px;
  }
}

.answers-test-list {
  &__table {
    width: 100%;
    margin-top: 24px;
  }
  &__badge {
    display: flex
  }
  &__table-list {
    border: 1px solid #E3E3E3; 
    border-radius: 4px 4px 0 0;
    display: flex;
  background-color: #F1F1F1;
    p:first-child {
      border-radius: 4px 0 0 0;
      padding-left: 29px;
      width: 20%;
    }
  }
  &__thead {
    width: 100%;
    p {
      text-align: left;
      padding: 15px 0;
      background: #F1F1F1; 
      color: #303030;
      font-feature-settings: 'salt' on;
      font-family: Source Sans Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.07px;
      width: 20%;
      @media(max-width: 1500px) {
        width: 15%;
        // &:last-child {
        //   width: 40%;
        // }
      }
    }
  }
  &__tbody-list {
    border: 1px solid #E3E3E3; 
    display: flex;
  }
  &__tbody-item {
      padding: 15px 0;
      color: #303030;
      font-feature-settings: 'salt' on;
      font-family: Source Sans Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.07px;
      display: flex;
      align-items: center;
      width: 20%;
      padding-left: 5px;
      @media(max-width: 1500px) {
        width: 15%;
        &:last-child {
          width: 40%;
        }
      }
      &:first-child {
        padding-left: 29px;
        width: 20%;
      }
      &--button {
        padding-right: 30px;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
      }
      .btn {
        display: flex;
        width: 195px;
        padding: 6px 12px;
        justify-content: center;
        font-weight: 600;
        flex-shrink: 0;

        &:first-child {
          @media(max-width: 1200px) {
            width: 100px;
            svg {
              margin-left: 10px;
              flex-shrink: 0;
            }
          }
        }
        &:nth-child(2) {
          width: 100px;
          padding: 6px 12px;
          svg {
            flex-shrink: 0;
            margin-left: 10px;
            path {
              stroke: #4F4294;
            }
          }
        }
        &:last-child {
          width: 40px;
          svg {
            flex-shrink: 0;
            path {
              stroke: #4F4294;
            }
          }
        }
        path {
          stroke: #4F4294;
        }
      }
  }
}