.first-page {
  display: flex;
  justify-content: center;
  height: calc(100vh - 150px);
  &__content {
    max-width: 564px;
    width: 100%;
    height: 100%;
    padding-top: 80px;
    display: flex;
    flex-wrap: wrap;
  }
  &__image {
    width: 100%;
    margin: 20px 0;
    img {
      width: 100%;
    }
  }
  &__agreement {
    margin: 20px 0;
  }
  &__content-screen {
    width: 100%;
    strong {
      font-weight: 700; 
    }
    p {
      color: #000;
      font-feature-settings: 'salt' on;
      font-family: Source Sans Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal; 
      margin: 15px 0;
    }
    em {
      font-style: italic;
    }
    ul {
      list-style: disc inside;
      padding-left: 0;
      li {
        text-decoration: dashed;
        font-size: 14px;
      }
    }
    ol {
      list-style-type: decimal;
      margin-left: 15px;
    }
  }
  &__buttons {
    width: 100%;
    display: flex;
    align-content: space-between;
    margin-top: auto;
    gap: 20px;
    margin-bottom: 50px;
    padding-bottom: 50px;
    padding-top: 30px;
    .btn {
      min-height: 48px;
      max-width: 50%;
      width: 100%;
    }
    &--right {
      margin-left: auto;
    }
  }
}